import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import { NameHeader } from '../components/NameHeader'
import { MainContent } from '../components/MainContent'
import { Footer } from '../components/Footer'
import { AnimatedContainer, Animation } from '../components/AnimatedContainer'
import { IntroAnimationHeader } from '../components/IntroAnimationHeader'

const INTRO_ANIMATION_END_DURATION = 2500

const IndexContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`

function IndexPage() {
  const [introAnimation, setIntroAnimation] = useState(Animation.hidden)
  const [animation, setAnimation] = useState(Animation.hidden)

  useEffect(() => {
    setIntroAnimation(Animation.show)

    setTimeout(() => {
      setIntroAnimation(Animation.leave)
    }, INTRO_ANIMATION_END_DURATION)

    setTimeout(() => {
      setAnimation(Animation.show)
    }, INTRO_ANIMATION_END_DURATION)
  }, [])

  return (
    <IndexLayout>
      <Page>
        <IntroAnimationHeader text={'Learning with no barriers.'} delay={500} animation={introAnimation} />
        <Container>
          <IndexContainer>
            <AnimatedContainer animation={animation} key={'Header'} delay={350}>
              <NameHeader />
            </AnimatedContainer>
            <AnimatedContainer animation={animation} key={'Content'} delay={200}>
              <MainContent />
            </AnimatedContainer>
            <AnimatedContainer key={'Footer'} animation={animation}>
              <Footer key={'Footer'} />
            </AnimatedContainer>
          </IndexContainer>
        </Container>
      </Page>
    </IndexLayout>
  )
}

export default IndexPage
