import React from 'react'
import styled from '@emotion/styled'

const IntroH1 = styled.h1`
  font-size: 4rem;
  font-weight: 500;
  margin-left: 1rem;
  color: #fff;
`

export interface IntoHeaderProps {
  text: string
}

export function IntoHeader({ text }: IntoHeaderProps) {
  return <IntroH1>{text}</IntroH1>
}
