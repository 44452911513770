import React from 'react'
import styled from '@emotion/styled'
import { FaGithub } from 'react-icons/fa'
import { graphql, useStaticQuery } from 'gatsby'

const StyledContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export interface HeaderQuery {
  site: {
    siteMetadata: {
      github: string
    }
  }
}

export function NameHeader() {
  const data = useStaticQuery<HeaderQuery>(graphql`
    {
      site {
        siteMetadata {
          github
        }
      }
    }
  `)

  return (
    <StyledContainer>
      <div />
      <div>
        <a target={'_blank'} href={data.site.siteMetadata.github}>
          <FaGithub color={'#fff'} size={24} style={{ marginLeft: '0.75rem' }} />
        </a>
      </div>
    </StyledContainer>
  )
}
