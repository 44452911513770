import React from 'react'

import styled from '@emotion/styled'

const Container = styled.div``

export function Footer() {
  return (
    <Container>
      <div />
    </Container>
  )
}
