import React from 'react'
import styled from '@emotion/styled'

// @ts-ignore
import Home from '../images/home.png'
// @ts-ignore
import AppStore from '../images/appStore.svg'
// @ts-ignore
import iPhone from '../images/iphone.png'
// @ts-ignore
import cursorShowcaseMp4 from '../video/cursor-showcase.mp4'
// @ts-ignore
import cursorShowcaseWebm from '../video/cursor-showcase.webm'
import { useStaticQuery, graphql } from 'gatsby'

const StyledContainer = styled.div``

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
`

const MainHeader = styled.h1`
  font-size: 4rem;
  font-weight: 500;
  color: #fff;
`

const TaglineContainer = styled.div`
  margin-bottom: 1.75rem;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`

const Description = styled.p`
  max-width: 35rem;
`

const FeatureList = styled.ul`
  margin-bottom: 2rem;
`

const FeatureListItem = styled.li`
  color: #fff;
`

const DeviceContainer = styled.div`
  position: absolute;
  top: -15rem;
  right: 1rem;
  width: 40rem;
  height: 80rem;
  background-image: url(${iPhone});
  background-repeat: no-repeat;
  background-size: contain;

  @media only screen and (max-width: 1500px) {
    top: -5rem;
    right: 10rem;
    width: 20rem;
    height: 40rem;
    justify-content: center;
  }

  @media only screen and (max-width: 1200px) {
    position: relative;
    top: 2rem;
    right: 0rem;
    margin: auto;
    width: 20rem;
    height: 40rem;
    justify-content: center;
  }
`

const DeviceVideo = styled.video`
  width: 100%;
  height: 100%;
  padding-top: 3rem;
  padding-left: 3.5rem;
  padding-right: 3.5rem;

  @media only screen and (max-width: 1500px) {
    padding-bottom: 3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`

export interface MainContentQuery {
  site: {
    siteMetadata: {
      description: string
      features: string[]
      appStoreLink: string
    }
  }
}

export function MainContent() {
  const data = useStaticQuery<MainContentQuery>(graphql`
    {
      site {
        siteMetadata {
          description
          features
          appStoreLink
        }
      }
    }
  `)

  const features = data.site.siteMetadata.features.map(feature => <FeatureListItem key={feature}>{feature}</FeatureListItem>)

  return (
    <StyledContainer>
      <TaglineContainer>
        <div>
          <HeadingContainer>
            <MainHeader>Cursor</MainHeader>
          </HeadingContainer>
          <Description style={{ color: 'white' }}>{data.site.siteMetadata.description}</Description>
          <FeatureList>{features}</FeatureList>
          <a href={data.site.siteMetadata.appStoreLink} target="_blank">
            <img src={AppStore} />
          </a>
        </div>
        <DeviceContainer>
          <DeviceVideo poster={Home} preload="metadata" loop autoPlay muted playsInline>
            <source src={cursorShowcaseMp4} type="video/mp4" />
            <source src={cursorShowcaseWebm} type="video/mp4" />
            Your browser does not support the video tag.
          </DeviceVideo>
        </DeviceContainer>
      </TaglineContainer>
    </StyledContainer>
  )
}
