import React from 'react'
import styled from '@emotion/styled'
import { Animation, AnimatedContainer } from './AnimatedContainer'
import { IntoHeader } from './IntroHeader'

const IntroContainer = styled.div`
  position: fixed;
  width: 50rem;
  height: 12.5rem;
  top: 50%;
  left: 50%;
  margin-top: -6rem;
  margin-left: -25rem;

  @media only screen and (max-width: 768px) {
    top: 30%;
    left: 5%;
    margin-top: -1.5rem;
    margin-left: -1rem;
  }
`

const IntroRowText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export interface IntroAnimationHeaderProps {
  animation: Animation
  delay: number
  text: string
}

export function IntroAnimationHeader({ animation, delay, text }: IntroAnimationHeaderProps) {
  const words = text.split(' ')
  const animatedWordContainers = words.map((word, index) => (
    <AnimatedContainer animation={animation} delay={delay * index}>
      <IntoHeader text={word} />
    </AnimatedContainer>
  ))
  return (
    <IntroContainer>
      <IntroRowText>{animatedWordContainers}</IntroRowText>
    </IntroContainer>
  )
}
